import React from "react"
import { graphql } from "gatsby"
import he from "he"
import PropTypes from "prop-types"

import Seo from "../components/seo"
import RenderBlock from "../utils/render-block"

const PageTemplate = ({ data }) => {

  return (
    <>
      {data.wpPage && <Seo title={he.decode(data.wpPage.seo.title)} description={data.wpPage.seo.metaDesc} path={`/${data.wpPage.slug}`} />}

      {data.wpPage &&
        data.wpPage.acf.contentBlocks &&
        data.wpPage.acf.contentBlocks.map((el, i) =>
          RenderBlock(el.fieldGroupName, el, i, "page")
        )}
    </>
  )
}

export const pageQuery = graphql`
  query($id: String) {
    wpPage(id: { eq: $id }) {
      slug
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        contentBlocks {
          ... on WpPage_Acf_ContentBlocks_Landing {
            fieldGroupName
            heading
            backgroundImage {
              localFile {
                publicURL
              }
            }
            backgroundImageMobile {
              localFile {
                publicURL
              }
            }
            s3video
          }
          ... on WpPage_Acf_ContentBlocks_LandingGallery {
            fieldGroupName
            heading
            images {
              localFile {
                publicURL
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_BackgroundImage {
            fieldGroupName
            isImageBanner
            imageData {
              localFile {
                publicURL
              }
            }
            isImageFullWidth
          }
          ... on WpPage_Acf_ContentBlocks_WhoWeAre {
            fieldGroupName
            arrowRequiredBelowSubHeading
            heading
            imageSlider {
              backgroundImage {
                localFile {
                  publicURL
                }
              }
            }
            subHeading
            subHeadingAlignedToBottom
            subHeadingWithLink {
              title
              url
            }
            textSlider {
              title
              date
              description
              fieldGroupName
              location
            }
            twoColumnInformation {
              informationContent
            }
          }
          ... on WpPage_Acf_ContentBlocks_HomeOurServices {
            fieldGroupName
            arrowRequiredBelowSubHeading
            asideInformation
            bannerImage {
              localFile {
                publicURL
              }
            }
            homeServicesListOne {
              homeServicesItemData1 {
                title
                url
              }
            }
            homeServicesListTwo {
              homeServicesListItem2 {
                title
                url
              }
            }
            subHeading
            heading
            subHeadingAlignedToBottom
            subHeadingWithLink {
              title
              url
            }
          }
          ... on WpPage_Acf_ContentBlocks_Partnerships {
            fieldGroupName
            arrowRequiredBelowSubHeading
            heading
            logoSlider {
              logoImage {
                localFile {
                  publicURL
                }
              }
            }
            subHeading
            subHeadingAlignedToBottom
            subHeadingWithLink {
              title
              url
            }
          }
          ... on WpPage_Acf_ContentBlocks_OurExperts {
            fieldGroupName
            arrowRequiredBelowSubHeading
            fieldGroupName
            heading
            subHeadingAlignedToBottom
            subHeading
            subHeadingWithLink {
              title
              url
            }
            teamMemberData {
              memberImage {
                localFile {
                  publicURL
                }
              }
              name
              role
            }
          }
          ... on WpPage_Acf_ContentBlocks_ServicesOurServices {
            fieldGroupName
            sectionImage {
              localFile {
                publicURL
              }
            }
            subHeading
            heading

            subHeadingWithLink {
              title
              url
            }
          }
          ... on WpPage_Acf_ContentBlocks_CareersAsideWithList {
            fieldGroupName
            asideTitle
            asideListData {
              asideItemData {
                title
                url
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_ServicesAsideWithList {
            fieldGroupName
            asideParagraph
            fieldGroupName
            asideDownloadLink {
              localFile {
                publicURL
              }
            }
            asideImage {
              localFile {
                publicURL
              }
            }
            asideListData {
              asideItemData {
                title
                url
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_AboutUs {
            fieldGroupName
            heading
            informationContainer {
              columnOneInformation
              columnTwoInformation
            }
            statisticsContainer {
              title
              value
            }
            subHeading
            subHeadingAlignedToBottom
            subHeadingWithLink {
              title
              url
            }
            video
            videoThumbnail {
              localFile {
                publicURL
              }
            }
            videoThumbnailMobile {
              localFile {
                publicURL
              }
            }
          }
          ... on WpPage_Acf_ContentBlocks_OurExpertsAbout {
            fieldGroupName
            arrowRequiredBelowSubHeading
            expertData {
              details
              email
              name
              profileImage {
                localFile {
                  publicURL
                }
              }
              role
            }
            heading
            memberCards {
              memberImage {
                localFile {
                  publicURL
                }
              }
              fieldGroupName
              name
              role
            }
            subHeading
            subHeadingAlignedToBottom
            subHeadingWithLink {
              title
              url
            }
          }
          ... on WpPage_Acf_ContentBlocks_CaseStudyLargeCard {
            fieldGroupName
            heading
            backgroundImage {
              localFile {
                publicURL
              }
            }
            subHeading
            viewProject {
              title
              url
            }
          }
          ... on WpPage_Acf_ContentBlocks_CaseStudySmallCard {
            fieldGroupName
            heading
            cardImage {
              localFile {
                publicURL
              }
            }
            popupLink {
              title
              url
            }
            subHeading
          }

          ... on WpPage_Acf_ContentBlocks_CaseStudiesList {
            fieldGroupName
            caseStudiesList
          }

          ... on WpPage_Acf_ContentBlocks_LastSection {
            fieldGroupName
            lastSectionData {
              backgroundImage {
                localFile {
                  publicURL
                }
              }
              backgroundImageMobile {
                localFile {
                  publicURL
                }
              }
              description
              fieldGroupName
              subTitle
              title
            }
          }
          ... on WpPage_Acf_ContentBlocks_ContactUs {
            additionalInformation
            bookMeeting
            bookMeetingLink
            subHeading
            heading
            contactEmail
            contactNumber
            fieldGroupName

            mapCoordinates {
              latitude
              longitude
              fieldGroupName
            }
            officeAddressDetails {
              city
              plot
              street
              title
            }
            postalAddressDetails {
              box
              city
              title
            }
            socialDetails {
              socialMediaLink {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`
PageTemplate.propTypes = {
  data: PropTypes.object,
}

export default PageTemplate
